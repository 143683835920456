import { isDefined } from "@clipboard-health/util-ts";
import { USER_EVENTS, UserEvent } from "@src/constants";
import { Shift, ShiftStages } from "@src/lib/interface";

export const getStageText = (stage: ShiftStages): string => {
  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return "Clock In";
    case ShiftStages.LUNCH_IN:
      return "End Break";
    case ShiftStages.LUNCH_OUT:
      return "Start Break";
    case ShiftStages.SKIP_LUNCH:
      return "Skip Break";
    case ShiftStages.CLOCK_OUT:
      return "Clock Out";
    case ShiftStages.SHIFT_TIME_DONE:
      return "Upload Your Timesheet";
    case ShiftStages.GET_FACILITY_SIGNATURE:
      return "Get Workplace Signature";
    case ShiftStages.GET_SIGNATURE:
      return "Get Signature";
    case ShiftStages.QR_CODE_SIGNATURE:
      return "Get signature and clock out";
    case ShiftStages.QR_CODE_CLOCK_OUT:
      return "Clock out and get paid";
    default:
      return "";
  }
};

const getStageDescription = (stage: ShiftStages) => {
  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return "clock in";
    case ShiftStages.LUNCH_IN:
      return "end your break";
    case ShiftStages.LUNCH_OUT:
      return "start your break";
    case ShiftStages.CLOCK_OUT:
      return "clock out";
    case ShiftStages.SHIFT_TIME_DONE:
      return "upload your timesheet";
    case ShiftStages.GET_FACILITY_SIGNATURE:
      return "get the workplace signature";
    case ShiftStages.GET_SIGNATURE:
      return "get the signature";
    case ShiftStages.QR_CODE_SIGNATURE:
      return "get signature and clock out";
    default:
      return "";
  }
};

type StageParams = {
  shift: Shift;
  isSignatureSubmission?: boolean;
  isCaliforniaTimesheetEnabled?: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  shiftHasNoFeedback?: boolean;
  qrCodeFeedbackBeforeClockOutIsEnabled?: boolean;
};

type InstantShiftStage = {
  currentStage: ShiftStages;
  currentStageText: string;
  currentStageDescription: string;
  currentStageLog: UserEvent | undefined;
};

function createStageResponse(stage: ShiftStages, log?: UserEvent): InstantShiftStage {
  return {
    currentStage: stage,
    currentStageText: getStageText(stage),
    currentStageDescription: getStageDescription(stage),
    currentStageLog: log,
  };
}

export function getStageObject(params: StageParams): InstantShiftStage {
  const {
    shift,
    isSolveUnpaidBreaksEnabled,
    isCaliforniaTimesheetEnabled,
    isSignatureSubmission,
    shiftHasNoFeedback,
    qrCodeFeedbackBeforeClockOutIsEnabled,
  } = params;
  const { verified = false, clockInOut = null, lunchInOut = null } = shift;

  if (!isDefined(clockInOut?.start)) {
    return createStageResponse(ShiftStages.CLOCK_IN, USER_EVENTS.CLOCK_IN);
  }

  const shiftHasBeenClockedOutOrVerified = verified || isDefined(clockInOut?.end);
  const qrCodeFlowIsEnabledAndNotInCalifornia =
    qrCodeFeedbackBeforeClockOutIsEnabled && !isCaliforniaTimesheetEnabled;
  const showClockOutStageForQRFlow = isSignatureSubmission && qrCodeFlowIsEnabledAndNotInCalifornia;

  if (shiftHasBeenClockedOutOrVerified && !showClockOutStageForQRFlow) {
    if (isSignatureSubmission && isCaliforniaTimesheetEnabled) {
      return {
        ...createStageResponse(ShiftStages.SHIFT_TIME_DONE),
        currentStageText: getStageText(ShiftStages.GET_SIGNATURE),
        currentStageDescription: getStageDescription(ShiftStages.GET_SIGNATURE),
      };
    }
    if (isSignatureSubmission) {
      return {
        ...createStageResponse(ShiftStages.SHIFT_TIME_DONE),
        currentStageText: getStageText(ShiftStages.GET_FACILITY_SIGNATURE),
        currentStageDescription: getStageDescription(ShiftStages.GET_FACILITY_SIGNATURE),
      };
    }
    return createStageResponse(ShiftStages.SHIFT_TIME_DONE);
  }

  const lunchHasEndedOrWasSkipped = isDefined(lunchInOut?.end) || lunchInOut?.isSkipped;
  const shouldReturnClockOutStageDetail = lunchHasEndedOrWasSkipped || isSolveUnpaidBreaksEnabled;

  if (qrCodeFlowIsEnabledAndNotInCalifornia && shouldReturnClockOutStageDetail) {
    return shiftHasNoFeedback
      ? createStageResponse(ShiftStages.QR_CODE_SIGNATURE, USER_EVENTS.CLOCK_OUT)
      : {
          ...createStageResponse(ShiftStages.CLOCK_OUT, USER_EVENTS.CLOCK_OUT),
          currentStageText: getStageText(ShiftStages.QR_CODE_CLOCK_OUT),
        };
  }

  /*
    Removing break actions when isSolveUnpaidBreaksEnabled is true.
    At the end of this effort we will completely remove break actions from the app.
    Adding breaks will be done in timecard upload step.
   */
  if (shouldReturnClockOutStageDetail) {
    return createStageResponse(ShiftStages.CLOCK_OUT, USER_EVENTS.CLOCK_OUT);
  }

  if (isDefined(lunchInOut?.start)) {
    return createStageResponse(ShiftStages.LUNCH_IN, USER_EVENTS.END_BREAK);
  }

  return createStageResponse(ShiftStages.LUNCH_OUT, USER_EVENTS.START_BREAK);
}
